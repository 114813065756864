.cell {
  min-width: 140px;
  height: 40px;
}

.filter {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  margin-bottom: 32px;
  padding: 12px;
  z-index: 999;
  border: 1px solid #dcdcde;
  border-radius: 8px;
}