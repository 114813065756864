.heat {
  position: relative;
  display: flex;
  grid-gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 24px;

  .column {
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-rows: repeat(7, 12px);
    grid-auto-flow: column;
    justify-content: flex-start;
    grid-gap: 2px;
    margin-top: 8px;
  }

  .cell {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: #c6c6c6;
    cursor: pointer;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.days {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 3px;

  &-cell {
    // height: 11px;
    line-height: 1;

    span {
      line-height: 1;
    }
  }
}