.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    min-height: 532px;
    background-color: #fff;
    border-radius: 20px;
  }

  .content {
    max-width: 380px;
    width: 100%;
    margin: 88px auto 78px;
  }
}
