.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 24;
  max-width: 600px;
  margin: 0 auto;
  min-height: 600px;
  height: calc(100vh - 80px);
}
