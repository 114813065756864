.container {
  padding: 84px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px 0;
  background-color: #fff;
  border-radius: 20px;
}

.menu {
  &:global(._mobile) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    grid-gap: 24px;
  }
}
