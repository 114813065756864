.activity {
  border: 1px solid #dcdcde;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 24px;

  .cell {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
}